import React, { useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import Logo from "../assets/logo.png";

const Hero: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header className="bg-white py-2 px-4 flex flex-col md:flex-row justify-between items-center">
      <div className="flex justify-between items-center w-full md:w-auto mb-2 md:mb-0 md:ml-20">
        <img src={Logo} alt="Logo" className="w-16" />
        <button
          className="md:hidden text-gray-600 hover:text-custom-hover transition-colors duration-300"
          onClick={handleToggleDrawer}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      {/* Desktop Navbar */}
      <nav className="hidden md:flex md:items-center md:space-x-5 md:mr-20">
        <ul className="flex flex-col md:flex-row md:space-x-5 space-y-2 md:space-y-0">
          <NavItem to="/" text="Home" />
          <NavItem to="/aboutUs" text="About Us" />
          <NavItem to="/contactUs" text="Contact Us" />
          <NavItem to="/business" text="Business Solutions" />
          <NavItem to="/blogs" text="Blogs" />
          <NavItem to="/safety" text="Trust and Safety" />
        </ul>
      </nav>
      {/* Mobile Drawer */}
      {isDrawerOpen && (
        <div className="md:hidden fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-20">
          <div className="bg-white p-6 rounded-lg shadow-lg relative">
            <button
              onClick={handleToggleDrawer}
              className="absolute top-1 right-1 p-3 rounded-full bg-custom-hover text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="space-y-2">
              <NavItem to="/" text="Home" handleToggleDrawer={handleToggleDrawer} />
              <NavItem to="/aboutUs" text="About Us" handleToggleDrawer={handleToggleDrawer} />
              <NavItem to="/contactUs" text="Contact Us" handleToggleDrawer={handleToggleDrawer} />
              <NavItem to="/business" text="Business Solutions" handleToggleDrawer={handleToggleDrawer} />
              <NavItem to="/blogs" text="Blogs" handleToggleDrawer={handleToggleDrawer} />
              <NavItem to="/safety" text="Trust and Safety" handleToggleDrawer={handleToggleDrawer} />
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

interface NavItemProps extends NavLinkProps {
  to: string;
  text: string;
  handleToggleDrawer?: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ to, text, handleToggleDrawer }) => {
  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? "text-custom-hover underline transition-colors duration-300 px-2 py-1 rounded-md text-md font-bold"
            : "text-gray-600 hover:text-custom-hover transition-colors duration-300 px-2 py-1 rounded-md text-md font-bold"
        }
        onClick={handleToggleDrawer}
      >
        {text}
      </NavLink>
    </li>
  );
};

export default Hero;


// import React, { useState } from "react";
// import { NavLink, NavLinkProps } from "react-router-dom";
// import Logo from "../assets/logo.png";

// const Hero: React.FC = () => {
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   const handleToggleDrawer = () => {
//     setIsDrawerOpen(!isDrawerOpen);
//   };

//   return (
//     <header className="bg-white py-2 px-4 flex flex-col md:flex-row justify-between items-center">
//       {/* <img src={Logo} alt="Logo" className="w-16 mb-2 md:mb-0 md:ml-20 " /> */}
//       <div className="w-full flex justify-start md:justify-left mb-2 md:mb-0  md:ml-20">
//         <img src={Logo} alt="Logo" className="w-20" />
//       </div>
//       <div>
//         {/* Desktop Navbar */}
//       <nav className="hidden md:flex md:items-center md:space-x-8 md:mr-60">
//         <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0">
//           <NavItem to="/" text="Home" />
//           <NavItem to="/aboutUs" text="About Us" />
//           <NavItem to="/contactUs" text="Contact Us" />
//           <NavItem to="/business" text="Business Solutions" />
//           <NavItem to="/blogs" text="Blogs" />
//           <NavItem to="/safety" text="Trust and Safety" />
//         </ul>
//       </nav>
//       </div>
      
//       {/* Mobile Corner Navigation */}
//       <button
//         className="md:hidden text-gray-600 hover:text-custom-hover transition-colors duration-300 absolute top-4 right-4 z-10"
//         onClick={handleToggleDrawer}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="h-8 w-8"
//           fill="none"
//           viewBox="0 0 24 24"
//           stroke="currentColor"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth={2}
//             d="M4 6h16M4 12h16M4 18h16"
//           />
//         </svg>
//       </button>
//       {/* Mobile Drawer */}
//       {isDrawerOpen && (
//         <div className="md:hidden fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-20">
//           <div className="bg-white p-6 rounded-lg shadow-lg">
//             <button
//               onClick={handleToggleDrawer}
//               className="absolute top-0 right-0 p-3 rounded-full bg-custom-hover text-white"
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-6 w-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <ul className="space-y-2">
//               <NavItem to="/" text="Home" handleToggleDrawer={handleToggleDrawer} />
//               <NavItem to="/aboutUs" text="About Us" handleToggleDrawer={handleToggleDrawer} />
//               <NavItem to="/contactUs" text="Contact Us" handleToggleDrawer={handleToggleDrawer} />
//               <NavItem to="/business" text="Business Solutions" handleToggleDrawer={handleToggleDrawer} />
//               <NavItem to="/blogs" text="Blogs" handleToggleDrawer={handleToggleDrawer} />
//               <NavItem to="/safety" text="Trust and Safety" handleToggleDrawer={handleToggleDrawer} />
//             </ul>
//           </div>
//         </div>
//       )}
//     </header>
//   );
// };

// interface NavItemProps extends NavLinkProps {
//   to: string;
//   text: string;
//   handleToggleDrawer?: () => void;
// }

// const NavItem: React.FC<NavItemProps> = ({ to, text, handleToggleDrawer }) => {
//   return (
//     <li>
//       <NavLink
//         to={to}
//         className={({ isActive }) =>
//           isActive
//             ? "text-custom-hover underline transition-colors duration-300 px-2 py-1 rounded-md text-md font-bold"
//             : "text-gray-600 hover:text-custom-hover transition-colors duration-300 px-2 py-1 rounded-md text-md font-bold"
//         }
//         onClick={handleToggleDrawer}
//       >
//         {text}
//       </NavLink>
//     </li>
//   );
// };

// export default Hero;

// import React from "react";
// import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";

// const ContactUs: React.FC = () => {
//   return (
//     <div className="container mx-auto px-40 py-16">
//       <section className="mb-12">
//         <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8">Get in Touch</h2>
//         <p className="text-base md:text-lg text-gray-700 mb-6">
//           Have questions or need assistance? Our team is here to help. Reach out to us through the following channels:
//         </p>
//         <ul className="text-base md:text-lg text-gray-700 mb-8">
//           <li className="flex items-center mb-4">
//             <span className="mr-4">
//               <FaEnvelope className="text-lg md:text-xl text-custom-hover" />
//             </span>
//             <span>Email: contact@qwikdealz.in</span>
//           </li>
//           <li className="flex items-center mb-4">
//             <span className="mr-4">
//               <FaPhone className="text-lg md:text-xl text-custom-hover" />
//             </span>
//             <span>Phone: +91-9696780988</span>
//           </li>
//           <li className="flex items-center">
//             <span className="mr-4">
//               <FaMapMarkerAlt className="text-lg md:text-xl text-custom-hover" />
//             </span>
//             <span>Address: 1/493 Ambedkarpuram, awas vikas-3, kalyanpur, Kanpur, 208017</span>
//           </li>
//         </ul>
//         <p className="text-base md:text-lg text-gray-700 mb-8">
//           Follow us on social media to stay updated on the latest deals and news from Qwik Dealz.
//         </p>
//         <ul className="flex space-x-4">
//           <li>
//             <a
//               href="[Your Facebook Link]"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
//             >
//               <FaFacebook className="text-2xl md:text-3xl" />
//             </a>
//           </li>
//           <li>
//             <a
//               href="[Your Twitter Link]"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
//             >
//               <FaTwitter className="text-2xl md:text-3xl" />
//             </a>
//           </li>
//           <li>
//             <a
//               href="[Your Instagram Link]"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
//             >
//               <FaInstagram className="text-2xl md:text-3xl" />
//             </a>
//           </li>
//           <li>
//             <a
//               href="[Your LinkedIn Link]"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
//             >
//               <FaLinkedin className="text-2xl md:text-3xl" />
//             </a>
//           </li>
//         </ul>
//       </section>
//     </div>
//   );
// };

// export default ContactUs;


import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";

const ContactUs: React.FC = () => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <section className="mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8">Get in Touch</h2>
        <p className="text-base md:text-lg text-gray-700 mb-6">
          Have questions or need assistance? Our team is here to help. Reach out to us through the following channels:
        </p>
        <ul className="text-base md:text-lg text-gray-700 mb-8">
          <li className="flex items-center mb-4">
            <span className="mr-4">
              <FaEnvelope className="text-lg md:text-xl text-custom-hover" />
            </span>
            <span>Email: contact@qwikdealz.in</span>
          </li>
          <li className="flex items-center mb-4">
            <span className="mr-4">
              <FaPhone className="text-lg md:text-xl text-custom-hover" />
            </span>
            <span>Phone: +91-9696780988</span>
          </li>
          <li className="flex items-center">
            <span className="mr-4">
              <FaMapMarkerAlt className="text-lg md:text-xl text-custom-hover" />
            </span>
            <span>Address: 1/493 Ambedkarpuram, awas vikas-3, kalyanpur, Kanpur, 208017</span>
          </li>
        </ul>
        <p className="text-base md:text-lg text-gray-700 mb-8">
          Follow us on social media to stay updated on the latest deals and news from Qwik Dealz.
        </p>
        <ul className="flex space-x-4">
          <li>
            <a
              href="[Your Facebook Link]"
              target="_blank"
              rel="noopener noreferrer"
              className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
            >
              <FaFacebook className="text-2xl md:text-3xl" />
            </a>
          </li>
          <li>
            <a
              href="[Your Twitter Link]"
              target="_blank"
              rel="noopener noreferrer"
              className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
            >
              <FaTwitter className="text-2xl md:text-3xl" />
            </a>
          </li>
          <li>
            <a
              href="[Your Instagram Link]"
              target="_blank"
              rel="noopener noreferrer"
              className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
            >
              <FaInstagram className="text-2xl md:text-3xl" />
            </a>
          </li>
          <li>
            <a
              href="[Your LinkedIn Link]"
              target="_blank"
              rel="noopener noreferrer"
              className="text-custom-hover hover:text-indigo-500 transition-colors duration-300"
            >
              <FaLinkedin className="text-2xl md:text-3xl" />
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ContactUs;

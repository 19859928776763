import React from 'react';

const RefundCancellationPolicy: React.FC = () => {
  return (
    <div className="bg-gray-200 min-h-screen py-12 px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-8">Cancellation and Refund Policy</h1>
        
        <p className="text-gray-800">
          At Qwik Dealz, we strive to ensure that our customers and merchants have a smooth and satisfactory experience. This Refund and Cancellation Policy outlines the terms and conditions for refunds and cancellations of purchases made through the Qwik Dealz platform ("Platform"). By using our Platform, you agree to this policy. If you do not agree, please do not use our services.
        </p>

        <p className="text-gray-800 mt-4">
          Please read the terms and conditions carefully before registering, accessing, or using the Qwik Dealz Services (defined below). The terms and conditions are a legal contract (“Agreement”) between You and Qwik Dealz managed and run by 7Fox Solutions Private Limited (“Qwik Dealz”) having its registered office at Office-1/493 Ambedkarpuram awas vikas 3, kalyanpur, Kanpur nagar, Uttar Pradesh, India, 208017. You agree and acknowledge that you have read the terms and conditions set forth below. If you do not agree to these terms and conditions or do not wish to be bound by these terms and conditions, you may not use the Services and/or immediately terminate the Services and/or uninstall the mobile application.
        </p>

        <h2 className="text-xl font-bold text-gray-900 mt-6 mb-4">1. Refund Policy</h2>

        <h3 className="text-lg font-bold text-gray-900 mb-2">1.1 Eligibility for Refunds</h3>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Products: Refunds for products purchased through the Platform are subject to the return policy of the respective merchant. Please review the merchant’s return policy before making a purchase.</li>
          <li>Services: Refunds for services are generally not available once the service has been rendered. However, exceptions may be made in cases of service failure or discrepancies between the service description and what was delivered.</li>
        </ul>

        <h3 className="text-lg font-bold text-gray-900 mt-4 mb-2">1.2 Requesting a Refund</h3>
        <ul className="list-disc pl-6 text-gray-800">
          <li>To request a refund, contact our customer support team at <a href="mailto:contact@qwikdealz.in">contact@qwikdealz.in</a> within 3 days of your purchase. Include your order number, the reason for the refund request, and any relevant details.</li>
          <li>Refund requests will be reviewed, and you will be notified of the outcome within 7 business days.</li>
        </ul>

        <h3 className="text-lg font-bold text-gray-900 mt-4 mb-2">1.3 Processing Refunds</h3>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Approved refunds will be processed to the original payment method used for the purchase.</li>
          <li>Refund processing times may vary depending on your payment provider. Typically, refunds are processed within 14 business days.</li>
        </ul>

        <h2 className="text-xl font-bold text-gray-900 mt-6 mb-4">2. Cancellation Policy</h2>

        <h3 className="text-lg font-bold text-gray-900 mb-2">2.1 Order Cancellations</h3>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Products: Orders for physical products can be canceled within 24 hours of placing the order, provided that the order has not yet been shipped. Once shipped, cancellations are not permitted, and you must follow the refund procedure outlined above.</li>
          <li>Services: Orders for services can be canceled within 24 hours of placing the order, provided that the service has not yet been rendered. If the service has commenced, cancellations are not permitted.</li>
        </ul>

        <h3 className="text-lg font-bold text-gray-900 mt-4 mb-2">2.2 Subscription Services</h3>
        <ul className="list-disc pl-6 text-gray-800">
          <li>If you have subscribed to a recurring service, you may cancel your subscription at any time. Cancellation will take effect at the end of the current billing cycle, and no further charges will be made.</li>
          <li>To cancel a subscription, log into your account and follow the cancellation instructions in your account settings or contact customer support.</li>
        </ul>

        <h3 className="text-lg font-bold text-gray-900 mt-4 mb-2">2.3 How to Cancel an Order</h3>
        <ul className="list-disc pl-6 text-gray-800">
          <li>To cancel an order, contact our customer support team at <a href="mailto:contact@qwikdealz.in">contact@qwikdealz.in</a> with your order number and the reason for cancellation.</li>
          <li>You will receive a confirmation of your cancellation request within 7 business days.</li>
        </ul>

        <h2 className="text-xl font-bold text-gray-900 mt-6 mb-4">3. Non-Refundable and Non-Cancellable Items</h2>
        <p className="text-gray-800">
          Certain items and services are non-refundable and non-cancellable, including:
        </p>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Digital products or services that have been downloaded or accessed.</li>
          <li>Customized or personalized products.</li>
          <li>Gift cards and vouchers.</li>
          <li>Any other items specified as non-refundable or non-cancellable at the time of purchase.</li>
        </ul>

        <h2 className="text-xl font-bold text-gray-900 mt-6 mb-4">4. Changes to This Policy</h2>
        <p className="text-gray-800">
          We reserve the right to modify this Refund and Cancellation Policy at any time. Any changes will be effective immediately upon posting the revised policy on the Platform. Your continued use of the Platform following the posting of changes constitutes your acceptance of such changes.
        </p>

        <h2 className="text-xl font-bold text-gray-900 mt-6 mb-4">5. Contact Us</h2>
        <p className="text-gray-800">
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </p>
        <address className="text-gray-800">
          Qwik Dealz<br />
          Aryan Gautam<br />
          1/493 Ambedkarpuram awas vikas 3, kalyanpur, kanpur, uttar pradesh, 208017, India<br />
          <a href="mailto:contact@qwikdealz.in">contact@qwikdealz.in</a><br />
          +91-9696780988
        </address>

        <p className="text-gray-800 mt-4">
          By using the Qwik Dealz Platform, you acknowledge that you have read, understood, and agree to be bound by this Refund and Cancellation Policy.
        </p>
      </div>
    </div>
  );
};

export default RefundCancellationPolicy;

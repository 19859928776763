import React from "react";
import Card from "../components/card";
import Rajat from "../assets/rajat.jpg";
import Prashant from "../assets/prashant.jpg";
// import ShuffleHero from "../components/shuffle";

const AboutUs: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 md:py-16">
      {/* <div className="mb-10">
        <ShuffleHero />
      </div> */}
      
      <section className="mb-12">
        <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6">
          In 2020, our founder embarked on an entrepreneurial journey with a digital marketing agency, gaining invaluable experience in the digital landscape. However, soon realized the need for a more impactful venture that could address the challenges faced by local businesses and consumers. This realization led to the creation of Qwik Dealz.
        </p>
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">About Qwik Dealz</h2>
        <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6">
          At Qwik Dealz, we believe in empowering consumers and local businesses through technology. Our mission is to create a seamless and rewarding shopping experience for everyone. We connect you with the best deals and rewards in your neighborhood, making it easier to support local businesses while saving money.
        </p>
        
        <div className="flex flex-col md:flex-row items-center md:space-x-6 mb-6">
          <img
            src="https://www.billdu.com/wp-content/uploads/2023/04/Steps-to-prosperity-during-a-crisis.png"
            alt="Our Vision"
            className="w-full h-auto md:w-1/2 mt-4 md:mt-0"
          />
          <div className="mt-4 md:mt-0 md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Vision</h2>
            <p className="text-gray-700 text-base md:text-lg leading-relaxed">
              To revolutionize the hyperlocal marketplace by providing unparalleled value to both consumers and merchants through innovative fintech solutions.
            </p>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row items-center md:space-x-6">
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Mission</h2>
            <p className="text-gray-700 text-base md:text-lg leading-relaxed">
              To bridge the gap between local businesses and consumers by offering a platform that delivers personalized deals, rewards, and a superior shopping experience.
            </p>
          </div>
          <img
            src="https://alvys.com/wp-content/uploads/2023/09/person-riding-a-rocket-success-1536x768.jpg"
            alt="Our Mission"
            className="w-full h-auto md:w-1/2 mt-4 md:mt-0"
          />
        </div>
      </section>

      <section>
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 text-center mb-8">Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <Card image={Rajat} title="Rajat Singh" description="Founder and CEO" />
          <Card image="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png" title="Jay Wardhan Singh" description="Co-Founder and Operations Manager" />
          <Card image={Prashant} title="Prashant Nishad" description="CTO" />
          <Card image="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png" title="Jason Morais" description="Investor cum Advisor" />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;

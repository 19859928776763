import React from 'react';

const GrievancePolicy: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen">

      <div className="container mx-auto px-8 py-12">
        <div className="bg-white rounded-lg p-8 shadow-md">
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">Grievance Policy</h1>

          <p className="text-gray-700 mb-6">
            At Qwik Dealz, we are committed to providing our users with a positive experience. We value your feedback
            and strive to resolve any issues promptly and fairly. This Grievance Policy outlines the process for
            addressing grievances related to the use of our platform ("Platform"). By using our Platform, you agree to
            this policy. If you do not agree, please do not use our services.
          </p>

          <h2 className="text-xl font-bold text-gray-900 mb-3">1. Scope of the Policy</h2>
          <p className="text-gray-700 mb-6">
            This policy applies to all users of the Qwik Dealz Platform, including customers and merchants, and covers
            grievances related to:
          </p>
          <ul className="list-disc list-inside mb-6">
            <li>Transactions and orders</li>
            <li>Product and service quality</li>
            <li>Account and payment issues</li>
            <li>Privacy concerns</li>
            <li>Any other issues related to the use of our Platform</li>
          </ul>

          <h2 className="text-xl font-bold text-gray-900 mb-3">2. How to Submit a Grievance</h2>
          <div className="ml-4 mb-6">
            <h3 className="text-lg font-bold text-gray-900 mb-2">2.1 Contact Information</h3>
            <ul className="list-disc list-inside">
              <li>Name: Rajat Singh</li>
              <li>Email: srajat17051997@yahoo.com</li>
              <li>Phone: +91 6393678689</li>
              <li>Address: 1/493 ambedkarpuram awas vikas 3, kalyanpur, kanpur</li>
            </ul>
          </div>
          <div className="ml-4 mb-6">
            <h3 className="text-lg font-bold text-gray-900 mb-2">2.2 Grievance Submission</h3>
            <p className="text-gray-700">
              Include the following information in your grievance submission:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Your name and contact details (email, phone number)</li>
              <li>A detailed description of the grievance</li>
              <li>Relevant order or transaction numbers, if applicable</li>
              <li>Any supporting documents or evidence</li>
            </ul>
          </div>

          <h2 className="text-xl font-bold text-gray-900 mb-3">3. Grievance Handling Process</h2>
          <div className="ml-4 mb-6">
            <h3 className="text-lg font-bold text-gray-900 mb-2">3.1 Acknowledgment</h3>
            <p className="text-gray-700 mb-3">
              Upon receiving your grievance, we will acknowledge receipt within 7 business days and provide you with a
              reference number for tracking purposes.
            </p>
            <h3 className="text-lg font-bold text-gray-900 mb-2">3.2 Review and Investigation</h3>
            <p className="text-gray-700 mb-3">
              Our Grievance Officer will review and investigate the grievance. This may involve contacting you for
              further information or clarification. The investigation process will be conducted fairly and impartially,
              considering all relevant facts and circumstances.
            </p>
            <h3 className="text-lg font-bold text-gray-900 mb-2">3.3 Resolution</h3>
            <p className="text-gray-700 mb-3">
              We aim to resolve grievances within 14 business days of receipt. However, some issues may require
              additional time for thorough investigation. You will be notified of the outcome of the investigation and
              the resolution proposed. If the grievance is upheld, appropriate corrective actions will be taken.
            </p>
            <h3 className="text-lg font-bold text-gray-900 mb-2">3.4 Escalation</h3>
            <p className="text-gray-700 mb-3">
              If you are not satisfied with the resolution provided, you may escalate the grievance to a higher
              authority within our organization. Details of the escalation process will be provided upon request.
            </p>
          </div>

          <h2 className="text-xl font-bold text-gray-900 mb-3">4. Confidentiality</h2>
          <p className="text-gray-700 mb-6">
            All grievances will be handled with strict confidentiality. Your personal information will only be shared
            with those involved in the grievance resolution process.
          </p>

          <h2 className="text-xl font-bold text-gray-900 mb-3">5. Monitoring and Improvement</h2>
          <p className="text-gray-700 mb-6">
            We regularly monitor and review grievances to identify trends and areas for improvement. Feedback from the
            grievance process is used to enhance our services and policies.
          </p>

          <h2 className="text-xl font-bold text-gray-900 mb-3">6. Changes to This Policy</h2>
          <p className="text-gray-700 mb-6">
            We reserve the right to modify this Grievance Policy at any time. Any changes will be effective immediately
            upon posting the revised policy on the Platform. Your continued use of the Platform following the posting of
            changes constitutes your acceptance of such changes.
          </p>

          <h2 className="text-xl font-bold text-gray-900 mb-3">7. Contact Us</h2>
          <p className="text-gray-700 mb-6">
            If you have any questions or concerns about this Grievance Policy, please contact us at:
          </p>
          <p className="text-gray-700 mb-6">
            Qwik Dealz
            <br />
            Aryan Gautam
            <br />
            1/493 ambedkarpuram awas vikas 3, kalyanpur, kanpur, uttar pradesh, 208017, India
            <br />
            contact@qwikdealz.in
            <br />
            +91-9696780988
          </p>

          <p className="text-gray-700">
            By using the Qwik Dealz Platform, you acknowledge that you have read, understood, and agree to be bound by
            this Grievance Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GrievancePolicy;

import React from 'react';
import { ReactComponent as PlayStore } from "../assets/svg/playstore.svg";
import { ReactComponent as AppStore } from "../assets/svg/appstore.svg";
import { Props } from '../api/types';


const RatingCard: React.FC<Props> = ({
  appStoreRating,
  googlePlayRating,
  appStoreUrl,
  googlePlayUrl,
}) => {
  return (
    <div className="bg-custom-hover py-6 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 rounded-lg mt-14">
      <div className="flex flex-col md:flex-row justify-center items-center md:items-start w-full max-w-5xl mx-auto">
        {/* App Store Section */}
        <div className="flex flex-col items-center mb-8 md:mb-0 md:mr-12">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-2">{appStoreRating}</h2>
          <p className="text-lg text-white mb-2">On App Store</p>
          <div className="flex items-center mb-4">
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                className={`mr-1 text-lg text-yellow-400 ${
                  index < appStoreRating ? 'fill-current' : 'fill-transparent'
                }`}
              >
                ★
              </span>
            ))}
          </div>
          <a
            href={appStoreUrl}
            className="flex items-center justify-center px-6 py-3 rounded-md bg-black text-white font-medium hover:bg-gray-700 outline outline-white text-center"
          >
            <AppStore className="w-8 h-8 md:w-9 md:h-9 mr-2" />
            <div>
              Download on <br className="hidden md:block" />
              <span className='text-xl md:text-2xl'>App Store</span>
            </div>
          </a>
        </div>

        {/* Google Play Section */}
        <div className="flex flex-col items-center">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-2">{googlePlayRating}</h2>
          <p className="text-lg text-white mb-2">On Google Play</p>
          <div className="flex items-center mb-4">
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                className={`mr-1 text-lg text-yellow-400 ${
                  index < googlePlayRating ? 'fill-current' : 'fill-transparent'
                }`}
              >
                ★
              </span>
            ))}
          </div>
          <a
            href={googlePlayUrl}
            className="flex items-center justify-center px-6 py-3 rounded-md bg-black text-white font-medium hover:bg-gray-700 outline outline-white text-center"
          >
            <PlayStore className="w-8 h-8 md:w-9 md:h-9 mr-2" />
            <div>
              GET IT ON <br className="hidden md:block" />
              <span className='text-xl md:text-2xl'>Google Play</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default RatingCard;

import React from "react";

const Sponsors: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center py-8 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 bg-background-hover  ">
      <h2 className="text-4xl sm:text-4xl font-bold mb-8 text-center text-white mt-5">
        Become a <span className="text-yellow-400">Sponsor</span>
      </h2>
      <p className="text-white sm:text-xl text-center mt-5">
      Your brand will be showcased in front of thousands of users, increasing brand recognition and
      driving traffic to your business.
      </p>
      
      <div className="flex flex-col md:flex-row items-center w-full max-w-6xl">
      <div className="md:w-1/2 md:pr-2 mb-6 md:mb-0">
          <img
            src="https://www.synersoft.in/wp-content/uploads/2017/08/partnership.png"
            alt="Quick Deals"
            className="w-full h-auto object-contain mt-10"
          />
        </div>
        <div className="md:w-1/2 text-white text-base sm:text-lg md:text-xl leading-relaxed space-y-4 md:space-y-6 ml-10 " style={{marginTop:"-3rem"}}>
          <p>
            ●{" "}
            <span className="text-yellow-300 font-bold">
            Exclusive Promotions
            {" "}
            </span>
            : Offer special deals and discounts to attract new customers and retain existing ones.
          </p>
          <p>
            ● <span className="text-yellow-300 font-bold"> Customer Analytics</span> :
            Access valuable insights into consumer behavior and preferences.
          </p>
          <p>
            ●{" "}
            <span className="text-yellow-300 font-bold">
              {" "}
              Easy Setup
            </span>{" "}
            : Get started quickly and effortlessly. Our team will assist you in integrating your
promotions and rewards into the Qwik Dealz platform, ensuring a smooth setup
process.

          </p>
          <p>
            ●{" "}
            <span className="text-yellow-300 font-bold">
              {" "}
              Build Brand Loyalty{" "}
            </span>
            : Enhance your brand's reputation by aligning with a platform that prioritizes local
            businesses and community well-being.
          </p>
        
        <button className="bg-blue-700 hover:bg-opacity-70 text-white font-semibold py-2 px-4 rounded-lg p-4 shadow-lg outline outline-white">Register Now</button>
      </div>
      </div>
    </div>
  );
};

export default Sponsors;

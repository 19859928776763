import React from 'react';
import { customerProps } from '../api/types';
import { ReactComponent as PlayStore } from "../assets/svg/playstore.svg";

const Dealz: React.FC<customerProps> = ({ googlePlayUrl }) => {
  return (
    <div className="flex flex-col items-center justify-center py-8 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 bg-background-hover mt-10">
      <h2 className="text-4xl sm:text-4xl font-bold mb-8 text-center text-white mt-5">
        Why Choose <span className="text-yellow-400">Qwik Dealz</span> ?
      </h2>
      <div className="flex flex-col md:flex-row items-center w-full max-w-6xl">
        <div className="md:w-1/2 md:pr-8 mb-6 md:mb-0">
          <img
            src="https://www.pngarts.com/files/8/Mobile-App-PNG-Photo.png"
            alt="Quick Deals"
            className="w-full h-auto object-contain mt-12"
          />
        </div>
        <div className="md:w-1/2 text-white text-base sm:text-lg md:text-xl leading-relaxed space-y-4 md:space-y-6 ml-10 ">
          <p>
            <span className="font-bold">● Exclusive Offers:</span> Access special discounts and promotions that you won't find anywhere else.
          </p>
          <p>
            <span className="font-bold">● Local Favorites:</span> Discover hidden gems and popular spots in your community.
          </p>
          <p>
            <span className="font-bold">● Easy to Use:</span> Our user-friendly app makes it simple to find and redeem deals.
          </p>
          <p>
            <span className="font-bold">● Rewards Program:</span> Earn points with every purchase and unlock additional rewards.
          </p>
          <h2 className="text-3xl sm:text-4xl font-bold mt-14">Start Saving Today</h2>
          <p>Download the Qwik Dealz app and start enjoying the benefits of smart shopping.</p>

          <a
            href={googlePlayUrl}
            className="flex items-center justify-center px-6 py-3 rounded-md bg-black text-white font-medium hover:bg-gray-700 outline outline-white text-center mt-10"
          >
            <PlayStore className="w-10 h-10 md:w-14 md:h-10 mr-2" />
            <div>
              GET IT ON <br className="hidden md:block" />
              <span className='text-xl md:text-2xl'>Google Play</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dealz;

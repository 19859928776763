import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="bg-gray-300 min-h-screen flex items-center justify-center py-12 px-8">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full">
        <h1 className="text-4xl font-bold text-center mb-8">Terms and Conditions (User)</h1>
        <div className="space-y-4">
          <p>
            Welcome to Qwik Dealz! These Terms and Conditions ("Terms") govern your use of our website and mobile application ("Platform"). By accessing or using our Platform, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Platform.
          </p>
          <p>
            Please read the terms and conditions carefully before registering, accessing or using the Qwik Dealz Services (defined below). The terms and conditions are a legal contract (“Agreement”) between You and Qwik Dealz managed and run by 7Fox Solutions Private Limited (“Qwik Dealz”) having its registered office at Office-1/493 Ambedkarpuram awas vikas 3, kalyanpur, Kanpur nagar, Uttar Pradesh, India, 208017. You agree and acknowledge that you have read the terms and conditions set forth below. If you do not agree to these terms and conditions or do not wish to be bound by these terms and conditions, you may not use the Services and/or immediately terminate the Services and/or uninstall the mobile application.
          </p>
          <h2 className="font-semibold">1. Definitions</h2>
          <ul className="list-disc list-inside">
            <li>"We," "Us," "Our": Refers to Qwik Dealz.</li>
            <li>"You," "User": Refers to any individual or entity accessing or using the Platform.</li>
            <li>"Platform": Refers to the Qwik Dealz website and mobile application.</li>
            <li>"Content": Refers to any data, information, text, graphics, photos, videos, or other materials uploaded, downloaded, or appearing on the Platform.</li>
          </ul>
          <h2 className="font-semibold">2. Eligibility</h2>
          <p>
            By using the Platform, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are under 18, you may only use the Platform under the supervision of a parent or legal guardian who agrees to be bound by these Terms.
          </p>
          <h2 className="font-semibold">3. Account Registration</h2>
          <ul className="list-disc list-inside">
            <li>To access certain features of the Platform, you must create an account.</li>
            <li>You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</li>
            <li>You are responsible for safeguarding your account credentials and are liable for any activity on your account.</li>
          </ul>
          <h2 className="font-semibold">4. Use of the Platform</h2>
          <ul className="list-disc list-inside">
            <li>You agree to use the Platform in accordance with all applicable laws and regulations.</li>
            <li>You shall not engage in any activity that interferes with or disrupts the Platform or the servers and networks connected to the Platform.</li>
            <li>You shall not use the Platform for any unauthorized or illegal purposes.</li>
          </ul>
          <h2 className="font-semibold">5. Content</h2>
          <ul className="list-disc list-inside">
            <li>You retain ownership of any Content you submit, post, or display on or through the Platform.</li>
            <li>By submitting, posting, or displaying Content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such Content in any and all media or distribution methods.</li>
            <li>We do not endorse or assume any responsibility for any Content uploaded or posted by users.</li>
          </ul>
          <h2 className="font-semibold">6. Rewards and Deals</h2>
          <ul className="list-disc list-inside">
            <li>Qwik Dealz provides access to various deals and rewards offered by third-party merchants.</li>
            <li>We do not guarantee the availability, quality, or accuracy of any deals or rewards.</li>
            <li>Users are responsible for verifying the terms and conditions of deals and rewards directly with the merchants.</li>
          </ul>
          <h2 className="font-semibold">7. Payments</h2>
          <ul className="list-disc list-inside">
            <li>Certain features of the Platform may be subject to payment.</li>
            <li>All payments made through the Platform are subject to our payment terms and conditions, which will be provided at the time of payment.</li>
          </ul>
          <h2 className="font-semibold">8. Termination</h2>
          <ul className="list-disc list-inside">
            <li>We reserve the right to suspend or terminate your account and access to the Platform at any time, for any reason, without notice.</li>
            <li>Upon termination, you remain liable for all obligations under these Terms.</li>
          </ul>
          <h2 className="font-semibold">9. Disclaimer of Warranties</h2>
          <ul className="list-disc list-inside">
            <li>The Platform is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied.</li>
            <li>We do not warrant that the Platform will be uninterrupted, error-free, or free of viruses or other harmful components.</li>
          </ul>
          <h2 className="font-semibold">10. Limitation of Liability</h2>
          <ul className="list-disc list-inside">
            <li>To the fullest extent permitted by applicable law, Qwik Dealz shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the Platform.</li>
          </ul>
          <h2 className="font-semibold">11. Governing Law</h2>
          <ul className="list-disc list-inside">
            <li>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.</li>
            <li>Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Kanpur, Uttar Pradesh, India.</li>
          </ul>
          <h2 className="font-semibold">12. Changes to the Terms</h2>
          <ul className="list-disc list-inside">
            <li>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Platform.</li>
            <li>Your continued use of the Platform following the posting of changes constitutes your acceptance of such changes.</li>
          </ul>
          <h2 className="font-semibold">13. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at,
          </p>
          <p>
            Mobile: +91-9696780988 <br />
            Email: contact@qwikdealz.in
          </p>
          <p>
            By using the Qwik Dealz Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

import React from "react";
import Hero from "../components/header";
import Player from "../components/player";
import Works from "../components/work";
import Dealz from "../components/deals";
import Merchant from "../components/merchant";
import Sponsors from "../components/sponsor";
import FaqSection from "../components/faq";
import Footer from "../components/footer";

function HomePage() {
  return (
    <div className="">
      <Player />
      <Works />
      <Dealz
      googlePlayUrl="https://play.google.com/store/apps/details?id=com.yourcompany.yourapp"
       />
       <Merchant
      googlePlayMerchantUrl="https://play.google.com/store/apps/details?id=com.yourcompany.yourapp"
       />
       <Sponsors />
       <FaqSection />
    </div>
  );
}

export default HomePage;

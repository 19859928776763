import React from "react";
import { MerchantProps } from "../api/types";
import { ReactComponent as PlayStore } from "../assets/svg/playstore.svg";

const Merchant: React.FC<MerchantProps> = ({ googlePlayMerchantUrl }) => {
  return (
    <div className="flex flex-col items-center justify-center py-8 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 bg-background-hover border border-white ">
      <h2 className="text-4xl sm:text-4xl font-bold mb-8 text-center text-white mt-5">
        Partner with <span className="text-yellow-400">Qwik Dealz</span>
      </h2>
      <p className="text-white sm:text-xl text-center">
        Join the Qwik Dealz network and attract more customers to your business.
        Our platform offers a unique opportunity to reach a wider audience and
        increase your sales through targeted promotions and rewards.
      </p>
      <div className="flex flex-col md:flex-row items-center w-full max-w-6xl">
        <div className="md:w-1/2 text-white text-base sm:text-lg md:text-xl leading-relaxed space-y-4 md:space-y-6 ml-10 ">
          <p>
            ●{" "}
            <span className="text-yellow-300 font-bold">
              Increase Foot Traffic{" "}
            </span>
            : Drive more customers to your store with exclusive deal.
          </p>
          <p>
            ● <span className="text-yellow-300 font-bold"> Boost Sales</span> :
            Encourage repeat visits and larger purchases with our rewards
            program.
          </p>
          <p>
            ●{" "}
            <span className="text-yellow-300 font-bold">
              {" "}
              Targeted Marketing
            </span>{" "}
            : Reach the right audience with personalized offers.
          </p>
          <p>
            ●{" "}
            <span className="text-yellow-300 font-bold">
              {" "}
              Discounts and Offers{" "}
            </span>
            : Create your own discount codes, offers, and sale.
          </p>
          <h2 className="text-3xl sm:text-4xl font-bold mt-14">
            Grow Your Business
          </h2>
          <p>
            Get started with Qwik Dealz today and watch your business thrive.
          </p>

          <a
            href={googlePlayMerchantUrl}
            className="flex items-center justify-center px-6 py-3 rounded-md bg-black text-white font-medium hover:bg-gray-700 outline outline-white text-center mt-10"
          >
            <PlayStore className="w-10 h-10 md:w-14 md:h-10 mr-2" />
            <div>
              GET IT ON <br className="hidden md:block" />
              <span className="text-xl md:text-2xl">Google Play</span>
            </div>
          </a>
        </div>
        <div className="md:w-1/2 md:pr-2 mb-6 md:mb-0">
          <img
            src="https://www.pngarts.com/files/16/E-Commerce-Free-PNG-Image.png"
            alt="Quick Deals"
            className="w-full h-auto object-contain mt-10"
          />
        </div>
      </div>
    </div>
  );
};

export default Merchant;

import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-gray-300 min-h-screen flex items-center justify-center py-12 px-8">
      <div className="bg-white rounded-lg p-8 mx-auto md:mx-20 lg:mx-40">
        <h1 className="text-2xl md:text-4xl font-bold text-center mb-8">Privacy Policy</h1>
        <div className="text-gray-700 text-base md:text-lg leading-relaxed space-y-6">
          <p>
            Qwik Dealz ("we," "us," or "our") respects your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and mobile application ("Platform"). By accessing or using our Platform, you agree to the terms of this Privacy Policy.
          </p>
          <p>
            Please read the terms and conditions carefully before registering, accessing or using the Qwik Dealz Services (defined below). The terms and conditions are a legal contract (“Agreement”) between You and Qwik Dealz managed and run by 7Fox Solutions Private Limited (“Qwik Dealz”) having its registered office at Office-1/493 Ambedkarpuram awas vikas 3, kalyanpur, Kanpur nagar, Uttar Pradesh, India, 208017. You agree and acknowledge that you have read the terms and conditions set forth below. If you do not agree to these terms and conditions or do not wish to be bound by these terms and conditions, you may not use the Services and/or immediately terminate the Services and/or uninstall the mobile application.
          </p>
          <h2 className="text-xl md:text-2xl font-bold mt-8">1. Information We Collect</h2>
          <h3 className="text-lg md:text-xl font-semibold mt-4">1.1 Personal Information</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Account Registration: When you create an account, we may collect your name, email address, phone number, and other contact information.</li>
            <li>Profile Information: Information you provide as part of your profile, such as your profile picture, interests, and preferences.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">1.2 Transaction Information</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Details of transactions you carry out through our Platform and the fulfillment of your orders, including payment information.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">1.3 Usage Data</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Information about how you use our Platform, such as your IP address, browser type, operating system, referral source, pages visited, and the dates/times of your visits.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">1.4 Device Information</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Information about the device you use to access our Platform, including the device's unique device identifier, operating system, and mobile network information.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">1.5 Location Information</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Information about your location if you have enabled location services on your device.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">1.6 Cookies and Tracking Technologies</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>We use cookies, web beacons, and similar technologies to collect information about your interactions with our Platform.</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-bold mt-8">2. How We Use Your Information</h2>
          <h3 className="text-lg md:text-xl font-semibold mt-4">2.1 Providing and Improving Our Services</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>To operate and maintain our Platform.</li>
            <li>To process and fulfill your transactions.</li>
            <li>To improve our Platform and customize your experience.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">2.2 Communication</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>To send you transactional and promotional emails, notifications, and messages.</li>
            <li>To respond to your inquiries and provide customer support.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">2.3 Marketing and Advertising</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>To send you promotional offers and information about products and services that may interest you.</li>
            <li>To deliver targeted advertising based on your preferences and usage patterns.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">2.4 Analytics and Research</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>To analyze usage trends and measure the effectiveness of our marketing efforts.</li>
            <li>To conduct research and gather insights to improve our services.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">2.5 Legal Compliance</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>To comply with applicable laws, regulations, and legal processes.</li>
            <li>To enforce our terms and conditions and protect our rights and interests.</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-bold mt-8">3. How We Share Your Information</h2>
          <h3 className="text-lg md:text-xl font-semibold mt-4">3.1 Service Providers</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Third-party vendors and service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and marketing assistance.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">3.2 Business Partners</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Merchants and business partners with whom we collaborate to offer deals and rewards.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">3.3 Legal and Regulatory Authorities</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Law enforcement agencies, regulatory bodies, and other authorities to comply with legal obligations and protect our rights and interests.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">3.4 Affiliates and Subsidiaries</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Our affiliates and subsidiaries for purposes consistent with this Privacy Policy.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">3.5 Other Users</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>Information you voluntarily share in public areas of the Platform, such as reviews and comments, may be visible to other users.</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-bold mt-8">4. Your Rights and Choices</h2>
          <h3 className="text-lg md:text-xl font-semibold mt-4">4.1 Access and Update</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>You can access and update your personal information by logging into your account and updating your profile settings.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">4.2 Opt-Out</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>You can opt out of receiving promotional emails by following the unsubscribe instructions in those emails. You can also manage your communication preferences in your account settings.</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">4.3 Delete</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>You can request the deletion of your account and personal information by contacting us at info@qwikdealz.in</li>
          </ul>
          <h3 className="text-lg md:text-xl font-semibold mt-4">4.4 Cookies</h3>
          <ul className="list-disc pl-5 space-y-2">
            <li>You can manage your cookie preferences through your browser settings. However, disabling cookies may affect your ability to use certain features of our Platform.</li>
          </ul>
          <h2 className="text-xl md:text-2xl font-bold mt-8">5. Data Security</h2>
          <p>
            We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
          </p>
          <h2 className="text-xl md:text-2xl font-bold mt-8">6. Children's Privacy</h2>
          <p>
            Our Platform is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it as soon as possible.
          </p>
          <h2 className="text-xl md:text-2xl font-bold mt-8">7. Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated policy on our Platform and updating the "Last Updated" date.
          </p>
          <h2 className="text-xl md:text-2xl font-bold mt-8">8. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
            <br />
            Email: info@qwikdealz.in
            <br />
            Address: Office-1/493 Ambedkarpuram awas vikas 3, kalyanpur, Kanpur nagar, Uttar Pradesh, India, 208017
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

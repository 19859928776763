import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import RatingCard from "./Rating";

const Player: React.FC = () => {
  return (
    <div className="bg-custom-hover">
      <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-center sm:items-center">
        {/* DotLottieReact animation */}
        <div className="p-4 sm:p-0 mt-20">
          <DotLottieReact
            src="https://lottie.host/db9a5ec1-2329-49d4-b94f-7d14950551c3/jcuY3VB8WG.json"
            loop
            autoplay
            style={{ width: "100%", maxWidth: "600px" }} 
          />
        </div>
     
        {/* Text content */}
        <div className="mt-6 sm:ml-9 sm:mt-0 text-center sm:text-left">
          <h1 className="text-3xl text-white">
            WELCOME TO <span className="text-4xl text-yellow-300 font-bold">QWIK DEALZ</span>
          </h1>
          <h2 className="text-4xl sm:text-5xl text-white mt-5 font-bold">
            Best Deals and Offers Nearby
          </h2>
          <h2 className="text-4xl sm:text-2xl text-white mt-8">
           Massive savings , Less spending !
          </h2>
        </div>
      </div>
      <RatingCard
  appStoreRating={4.8}
  googlePlayRating={4.7}
  appStoreUrl="https://apps.apple.com/us/app/your-app-name/id1234567890"
  googlePlayUrl="https://play.google.com/store/apps/details?id=com.yourcompany.yourapp"
/>
    </div>
  );
};

export default Player;

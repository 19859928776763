import React from 'react';
import './index.css';
import HomePage from './pages/home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Hero from './components/header';
import Footer from './components/footer';
import AboutUs from './pages/aboutUs';
import ContactUs from './pages/contactUs';
import PrivacyPolicy from './components/privacyPolicy';
import TermsAndConditions from './components/termsConditionsUsers';
import RefundCancellationPolicy from './components/cancellationRefund';
import GrievancePolicy from './components/grivencePolicy';

function App() {
  return (
    <>
    <BrowserRouter>
    <Hero />
        <Routes>
            <Route
                path=""
                element={<HomePage />}
            />
            <Route
                path="/aboutUs"
                element={<AboutUs />}
            />
            <Route
                path="/contactUs"
                element={<ContactUs />}
            />
              <Route
                path="/privacyPolicy"
                element={<PrivacyPolicy />}
            />
             <Route
                path="/terms&Conditions"
                element={<TermsAndConditions />}
            />
             <Route
                path="/refund&Cancellation"
                element={<RefundCancellationPolicy />}
            />
             <Route
                path="/grievancePolicy"
                element={<GrievancePolicy />}
            />
        </Routes>
        <div style={{marginBottom:"-4rem"}}>
        <Footer />
        </div>
       
    </BrowserRouter>
</>
  );
}

export default App;

import React from 'react';

interface FaqProps {
  question: string;
  answer: string;
}

const Faq = ({ question, answer }: FaqProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="mb-4 border-b border-gray-300 pb-4 w-full">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-medium text-gray-900">{question}</h3>
        <svg
          className={`w-6 h-6 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {isOpen && (
        <p className="mt-2 text-gray-700">{answer}</p>
      )}
    </div>
  );
};

const FaqSection = () => {
  return (
    <div className="container mx-auto px-4 py-8 md:py-16">
      <h2 className="text-2xl sm:text-4xl font-bold text-center text-gray-900 mb-8">
        Frequently Asked Questions
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1 mt-6">
        <Faq
          question="How do I sign up for Qwik Dealz?"
          answer="Simply download our app from the App Store or Google Play, and follow the registration process."
        />
        <Faq
          question="Is Qwik Dealz free to use?"
          answer="Yes, Qwik Dealz is completely free for consumers. You only pay for the products or services you purchase using the deals."
        />
        <Faq
          question="How do I redeem a deal?"
          answer="Show the deal on your app at the participating store to redeem it."
        />
        <Faq
          question="Can businesses from any location join Qwik Dealz?"
          answer="Currently, Qwik Dealz focuses on hyperlocal markets. Please contact us to see if your area is covered."
        />
      </div>
    </div>
  );
};

export default FaqSection;

import React from "react";

interface CardProps {
  image: string;
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ image, title, description }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-12 flex flex-col items-center outline outline-gray-300">
      <img src={image} alt={title} className="w-44 h-44 rounded-full mb-4" />
      <h3 className="text-xl font-bold text-gray-900 mt-4">{title}</h3>
      <p className="text-gray-700 text-center mt-4 text-large">{description}</p>
    </div>
  );
};

export default Card;

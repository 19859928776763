// import React from 'react';


// const Works: React.FC = () => {
//   return (
//     <div className="flex flex-col items-center py-8 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 bg-white mt-12">
//       <h1 className="text-3xl sm:text-4xl font-bold text-center text-black mb-8">
//         HOW <span className="text-yellow-400">QWIK DEALZ</span> WORKS
//       </h1>
      
//       <div className="w-full max-w-4xl flex flex-col md:flex-row items-center md:items-start mt-10">

//         <img
//           src={"https://www.pngarts.com/files/8/Mobile-App-PNG-Photo.png"}
//           alt="Quick Deals"
//           className="w-106 h-96 md:w-96 md:h-80 object-contain mb-2 md:mb-0 md:mr-6"
//         />

//         <div className="text-black text-base sm:text-lg md:text-xl leading-relaxed space-y-6 text-center md:text-left md:max-w-lg ml-20">
//           <p><span className="font-bold">1. Explore:</span> Browse through a variety of deals and offers from local businesses in your area.</p>
//           <p><span className="font-bold">2. Save:</span> Claim exclusive discounts and rewards directly through our app.</p>
//           <p><span className="font-bold">3. Shop:</span> Visit the store, show your deal, and enjoy the savings!</p>
//           <p><span className="font-bold">4. Earn:</span> Accumulate points with every purchase and redeem them for even more exciting rewards.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Works;

// HowItWorks.tsx
import React from 'react';

const Works: React.FC = () => {
  return (
    <div className="flex flex-col items-center py-12 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 bg-white mt-12">
      <h1 className="text-3xl sm:text-4xl font-bold text-center text-black mb-8">
        HOW <span className="text-yellow-400">QWIK DEALZ</span> WORKS
       </h1>
      
      <div className="w-full max-w-4xl flex flex-col items-center">
        <div className="text-black text-lg md:text-xl leading-relaxed space-y-6 mt-10">
          <p><span className="font-bold">1. Explore:</span> Browse through a variety of deals and offers from local businesses in your area.</p>
          <p><span className="font-bold">2. Save:</span> Claim exclusive discounts and rewards directly through our app.</p>
          <p><span className="font-bold">3. Shop:</span> Visit the store, show your deal, and enjoy the savings!</p>
          <p><span className="font-bold">4. Earn:</span> Accumulate points with every purchase and redeem them for even more exciting rewards.</p>
        </div>
      </div>
    </div>
  );
};

export default Works;


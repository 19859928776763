import React from 'react';
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-neutral-900 py-6">
      <div className="container mx-auto px-4 md:px-8">
        {/* Navigation Links */}
        <nav className="flex flex-col items-center md:items-center md:flex-row">
          <ul className="flex flex-col md:flex-row items-center gap-4 md:gap-6 mb-6 md:mb-0 md:ml-60">
            <FooterNavItem to="/terms&Conditions" text="Terms and Conditions" />
            <FooterNavItem to="/privacyPolicy" text="Privacy Policy" />
            <FooterNavItem to="/refund&Cancellation" text="Refund and Cancellation Policy" />
            <FooterNavItem to="/grievancePolicy" text="Grievance Policy" />
          </ul>
        </nav>

        {/* Divider Line */}
        <div className="border-b border-gray-200 my-6 w-full"></div>

        {/* Copyright */}
        <p className="text-center text-white mt-4 mb-2">
          Copyright © 2024 | 7Fox Solutions Private Limited
        </p>
      </div>
    </footer>
  );
};

interface FooterNavItemProps {
  to: string;
  text: string;
}

const FooterNavItem: React.FC<FooterNavItemProps> = ({ to, text }) => {
  return (
    <li>
      <NavLink
        to={to}
        className="text-gray-400 hover:text-white transition-colors duration-300"
      >
        {text}
      </NavLink>
    </li>
  );
};

export default Footer;
